/* eslint-disable @next/next/no-img-element */
"use client";

import { basePath } from "@/next.config";
import {
  auth,
  googleProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "@/shared/firebase/firebaseClient";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState, ChangeEvent, FormEvent } from "react";
import Head from "next/head"; // Import Head from next/head

export default function Home() {
  const [passwordshow1, setpasswordshow1] = useState(false);
  const [err, setError] = useState<string>("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = data;

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError("");
  };

  const Login = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      console.log(user);
      RouteChange();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log(result);
      RouteChange();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const resetPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const router = useRouter();
  const RouteChange = () => {
    const user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        const role = idTokenResult.claims.role;
        if (role === "admin") {
          router.push(`/admin/${user.uid}/miearnings`);
        } else if (role === "referrer") {
          router.push(`/referrer/${user.uid}/earnings`);
        } else if (role === "publisher") {
          router.push(`/publisher/${user.uid}/earnings`);
        } else {
          router.push("/");
        }
      });
    } else {
      router.push("/");
    }
  };

  return (
    <>
      <Head>
        <title>Sign In - Headerbiddr Dashboard</title>

        <meta
          name="description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />

        <meta property="og:url" content="https://dashboard.headerbiddr.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="HeaderBiddr - Elite Ad Performance"
        />
        <meta
          property="og:description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />
        <meta
          property="og:image"
          content="https://opengraph.b-cdn.net/production/images/58ab8781-bba4-4533-a4a1-cd0d87b4f5a9.png?token=L33cg74HJ4aOkdu_TRjwydZChBqMz-klNXA4-503Scs&height=630&width=1200&expires=33259345372"
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dashboard.headerbiddr.com" />
        <meta
          property="twitter:url"
          content="https://dashboard.headerbiddr.com"
        />
        <meta
          name="twitter:title"
          content="HeaderBiddr - Elite Ad Performance"
        />
        <meta
          name="twitter:description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />
        <meta
          name="twitter:image"
          content="https://opengraph.b-cdn.net/production/images/58ab8781-bba4-4533-a4a1-cd0d87b4f5a9.png?token=L33cg74HJ4aOkdu_TRjwydZChBqMz-klNXA4-503Scs&height=630&width=1200&expires=33259345372"
        />
      </Head>

      <body className="dark">
        <div className="container">
          <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
            <div className="grid grid-cols-12">
              <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
              <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
                <div className="my-[0.1rem] flex justify-center">
                  <Link href="/">
                    <img
                      src={`${
                        process.env.NODE_ENV === "production" ? basePath : ""
                      }/assets/images/brand-logos/toggle-dark.png`}
                      alt="logo"
                      className="desktop-logo"
                      width={150}
                      height={150}
                    />
                    <img
                      src={`${
                        process.env.NODE_ENV === "production" ? basePath : ""
                      }/assets/images/brand-logos/toggle-dark.png`}
                      alt="logo"
                      className="desktop-dark"
                      width={150}
                      height={150}
                    />
                  </Link>
                </div>

                <div className="box !p-[1rem]">
                  <div
                    className="box-body"
                    role="tabpanel"
                    id="pills-with-brand-color-01"
                    aria-labelledby="pills-with-brand-color-item-1"
                  >
                    <p className="h5 font-semibold mb-2 text-center">Sign In</p>
                    {err && (
                      <div
                        className="p-4 mb-4 bg-danger/40 text-sm  border-t-4 border-danger text-danger/60 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                        role="alert"
                      >
                        {err}
                      </div>
                    )}

                    <p className="mb-4 text-[#8c9097] dark:text-white/50 opacity-[0.7] font-normal text-center">
                      Welcome back!
                    </p>
                    <div className="grid grid-cols-12 gap-y-4">
                      <div className="xl:col-span-12 col-span-12">
                        <label
                          htmlFor="signin-email" // Ensure the 'for' value matches the 'id' of the input
                          className="form-label text-default"
                        >
                          Email
                        </label>
                        <input
                          type="text"
                          name="email"
                          className="form-control form-control-lg w-full !rounded-md"
                          id="signin-email"
                          onChange={changeHandler}
                          value={email}
                          autoComplete="email"
                        />
                      </div>
                      <div className="xl:col-span-12 col-span-12 mb-2">
                        <label
                          htmlFor="signin-password"
                          className="form-label text-default block"
                        >
                          Password
                          <Link
                            href="#!"
                            className="float-right text-danger"
                            onClick={resetPassword}
                          >
                            Forget password?
                          </Link>
                        </label>
                        <div className="input-group">
                          <input
                            name="password"
                            type={passwordshow1 ? "text" : "password"}
                            value={password}
                            onChange={changeHandler}
                            className="form-control  !border-s form-control-lg !rounded-s-md"
                            id="signin-password"
                            placeholder="password"
                            autoComplete="current-password"
                          />
                          <button
                            onClick={() => setpasswordshow1(!passwordshow1)}
                            aria-label="button"
                            className="ti-btn ti-btn-light !rounded-s-none !mb-0"
                            type="button"
                            id="button-addon2"
                          >
                            <i
                              className={`${
                                passwordshow1
                                  ? "ri-eye-line"
                                  : "ri-eye-off-line"
                              } align-middle`}
                            ></i>
                          </button>
                        </div>
                        <div className="mt-2">
                          <div className="form-check !ps-0">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              defaultValue=""
                              id="defaultCheck1"
                            />
                            <label
                              className="form-check-label text-[#8c9097] dark:text-white/50 font-normal"
                              htmlFor="defaultCheck1"
                            >
                              Remember password?
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-12 col-span-12 grid mt-0">
                        <button
                          onClick={Login}
                          className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                    <div className="text-center">
                      <p className="text-[0.75rem] text-[#8c9097] dark:text-white/50 mt-4">
                        Don't have an account?{" "}
                        <Link href="#!" className="text-primary">
                          Sign Up
                        </Link>
                      </p>
                    </div>
                    <div className="text-center my-4 authentication-barrier">
                      <span>OR</span>
                    </div>
                    <div className="text-center">
                      <button
                        onClick={googleLogin}
                        className="gsi-material-button"
                      >
                        <div className="gsi-material-button-state"></div>
                        <div className="gsi-material-button-content-wrapper">
                          <div className="gsi-material-button-icon">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 48 48"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              style={{ display: "block" }}
                            >
                              <path
                                fill="#EA4335"
                                d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                              ></path>
                              <path
                                fill="#4285F4"
                                d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                              ></path>
                              <path
                                fill="#FBBC05"
                                d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                              ></path>
                              <path
                                fill="#34A853"
                                d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                              ></path>
                              <path fill="none" d="M0 0h48v48H0z"></path>
                            </svg>
                          </div>
                          <span className="gsi-material-button-contents">
                            Sign in with Google
                          </span>
                          <span style={{ display: "none" }}>
                            Sign in with Google
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
}
